<template lang="pug">
.footer-atm-mitra
  .footer-atm-mitra--logo
    BLBPicture(src="/images/logos/atm-mitra-logo.png")
  .footer-atm-mitra--info.mt-48
    bl-flex-container-mv(
      alignItems="flex-start"
      justifyContent="space-between"
    )
      .footer-atm-mitra--info-contact
        bl-text-av(variation="heading-24") Kontak
        bl-text-av.mt-24 Whatsapp kami
      .footer-atm-mitra--info-findus
        bl-text-av(variation="heading-24") Media sosial
        bl-flex-container-mv.mt-24
          a.u-mrgn-right--4(
            :href="`${findUsLink.instagram}?utm_source=desktop_footer_atm_mitra`"
            target="_blank"
          )
            bl-icon-av(name="ico_instagram" color="#2E2E2E" :style="{ height: '100%' }")
          a.u-mrgn-right--4(
            :href="`${findUsLink.facebook}?utm_source=desktop_footer_atm_mitra`"
            target="_blank"
          )
            bl-icon-av(name="ico_facebook" color="#2E2E2E" :style="{ height: '100%' }")
          a.u-mrgn-right--4(
            :href="`${findUsLink.tiktok}?utm_source=desktop_footer_atm_mitra`"
            target="_blank"
          )
            icon-tiktok
          a.u-mrgn-right--4(
            :href="`${findUsLink.youtube}?utm_source=desktop_footer_atm_mitra`"
            target="_blank"
          )
            bl-icon-av(name="ico_play_circle" color="#2E2E2E" :style="{ height: '100%' }")
      .footer-atm-mitra--info-download
        bl-text-av(variation="heading-24") Download aplikasi
        .footer-atm-mitra--info-download-logo.mt-24
          a(:href="googlePlayUrl" target="_blank" rel="noopener")
            BLBPicture(
              src="/images/3rd-parties/google-play-button.png"
              :imgStyle="{ height: '46px' }"
              title="Download aplikasi via Google Play Store"
            )
  .footer-atm-mitra--copyright.mt-48
    bl-text-av © 2021 PT Buka Mitra Indonesia

</template>

<script>
import { mapGetters } from 'vuex';
import BLBPicture from '~/components/shared/base/BLBPicture.vue';
import BlFlexContainerMv from '@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import { BlIconAv } from '@bukalapak/bazaar-visual';
import IconTiktok from '~/components/NewFooter/IconTiktok.vue';

export default {
  name: 'FooterAtmMitra',
  components: {
    BLBPicture,
    BlFlexContainerMv,
    BlTextAv,
    BlIconAv,
    IconTiktok
  },
  computed: {
    ...mapGetters({
      findUsLink: 'global/findUsLink',
    }),
  },
  methods: {
    googlePlayUrl() {
      // TODO: implement
    },
  }
}
</script>

<style lang="scss" scoped>
.footer-atm-mitra{
  .footer-atm-mitra--logo{
    width: 150px;
  }
}
</style>
