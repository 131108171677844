<template lang="pug">
  .atm-mitra-page
    navigation(
      page-id='atm-mitra-page'
      cta-id="header-download-button"
      download-button-id="sandwich-menu"
    )
    #atm-mitra-hero
      atm-mitra-hero
    #atm-mitra-benefit
      bl-flex-container-mv(
        justifyContent="space-between"
      )
        bl-card-mv.atm-mitra-benefit-item(v-for="(benefit, index) in benefits", ::key="index" :border-radius="20")
          bl-flex-container-mv.ph-24.pv-24(
            direction="column"
            alignItems="center"
            justifyContent="center"
          )
            BLBPicture(:src="benefit.image")
            bl-text-av.mb-12(variation="heading-24" align="center") {{ benefit.name }}
            bl-text-av(align="center") {{ benefit.description }}
    #atm-mitra-service
      bl-card-mv.atm-mitra-service(:border-radius="40")
        bl-text-av.atm-mitra-service--title(variation="heading-42" align="center") Ada banyak layanan transaksinya 
        bl-flex-container-mv.atm-mitra-service--list.mt-48(justifyContent="space-between")
          bl-card-mv.ph-16.pv-16(v-for="(service, index) in services1", :key="index" :border-radius="20")
            BLBPicture(:src="service.image")
            bl-text-av.mv-12(variation="heading-24") {{ service.name }}
            bl-text-av(color="subdued") {{ service.description }}
        bl-flex-container-mv.atm-mitra-service--list.mt-48(justifyContent="space-between")
          bl-card-mv.ph-16.pv-16(v-for="(service, index) in services2", :key="index" :border-radius="20" :class="service.hidden ? 'hidden': ''")
            BLBPicture(:src="service.image")
            bl-text-av.mv-12(variation="heading-24") {{ service.name }}
            bl-text-av(color="subdued") {{ service.description }}
    #atm-mitra-review
      bl-text-av.atm-mitra-customer-review--title(variation="heading-42" align="center") Apa kata mereka yang udah coba layanannya?
      bl-flex-container-mv.atm-mitra-review--list.mt-48
        bl-card-mv.ph-32.pv-32.mh-16(v-for="(review, index) in reviews", :key="index")
          bl-text-av {{ review.review }}
          bl-flex-container-mv.mt-32
            .atm-mitra-review--photo.mr-16
              BLBPicture(:src="review.image")
            .atm-mitra-review--user-info
              bl-text-av(variation="heading-24") {{ review.name }}
              bl-text-av(color="subdued") {{ review.location }}
    #atm-mitra-guide
      bl-card-mv.atm-mitra-guide.pv-48.ph-48(:border-radius="40")
        bl-flex-container-mv.ph-24
          bl-flex-container-mv.atm-mitra-guide--title(
            direction="column"
            alignItems="self-start"
            justifyContent="center"
          )
            bl-text-av.atm-mitra-guide-title-header Cara daftar jadi
            bl-text-av.atm-mitra-guide-title-header Agen ATM Mitra
            bl-text-av.mt-32 Download aplikasi Mitra Bukalapak di Google Play Store
          bl-flex-container-mv.atm-mitra-guide--slide(
            direction="column"
            alignItems="center"
            justifyContent="center"
          )
            .atm-mitra-guide--slide-image
              BLBPicture(src="/images/landing/atm-mitra/phone.png")
            .atm-mitra-guide--slide-title.mt-24
              bl-text-av(variation="subheading-20", align="center") Download aplikasi Mitra Bukalapak
              bl-text-av(variation="subheading-20", align="center") di Google Playstore.
              bl-text-av(align="center").mt-24 --pagination--
    #atm-mitra-description
      bl-flex-container-mv(
        direction="column"
        alignItems="center"
        justifyContent="center"
      )
        bl-text-av.atm-mitra-description--title(variation="heading-24", align="center") Nikmati kemudahan transaksi virtual dan belanja produk digital
        bl-text-av.atm-mitra-description--description.mt-24(align="justify") Pelanggan dapat melakukan transaksi keuangan online dan belanja produk-produk digital dari warung Mitra Bukalapak terdekat tanpa perlu jauh-jauh cari ATM. Mulai dari kirim uang, tarik tunai, setor tunai, bayar QRIS, top up digital, pulsa, paket data, bayar virtual account, bayar angsuran, token listrik, dan masih banyak lagi.
        template(v-if="isFullDescription")
          bl-text-av.atm-mitra-description--description.mt-24(align="justify") Raih manfaat besar dengan bergabung menjadi mitra ATM Mitra! Setiap transaksi menghasilkan keuntungan, dilengkapi dengan peluang bonus dan cashback menarik. Nikmati berbagai promosi eksklusif yang menjadikan aktivitas transaksi tidak hanya praktis, tetapi juga menguntungkan.
          bl-text-av.atm-mitra-description--description.mt-24(align="justify") Tidak perlu repot membuka rekening bank atau kartu khusus untuk mengakses layanan finansial ATM Mitra. Semua transaksi bisa dilakukan langsung dari aplikasi Mitra Bukalapak, mulai dari kirim uang, tarik tunai, hingga pembayaran QRIS, yang memudahkan akses bagi kamu dan pelangganmu.
          bl-text-av.atm-mitra-description--description.mt-24(align="justify") Daftar sebagai agen ATM Mitra sangat mudah! Cukup dengan KTP, kamu bisa langsung memulai usaha finansial dengan proses registrasi cepat dan sederhana. ATM Mitra memberikan peluang bisnis yang menjanjikan dengan prosedur yang simpel.
          bl-text-av.atm-mitra-description--description.mt-24(align="justify") Keamanan transaksi adalah prioritas kami. ATM Mitra bermitra dengan bank dan lembaga keuangan yang diawasi oleh Bank Indonesia, menjamin pengalaman transaksi yang aman dan nyaman. Percayakan kebutuhan keuanganmu kepada ATM Mitra, solusi transaksi terpercaya untuk masa kini!
        bl-text-av.atm-mitra-description--button.mt-24(weight="bold", @click="toggleDescription") {{ isFullDescription ? 'Tutup': 'Baca selengkapnya' }}
    #atm-mitra-footer
      footer-atm-mitra
</template>

<script>
import Navigation from '~/components/Navigation/index.vue';
import AtmMitraHero from '~/components/AtmMitraPage/AtmMitraHero/index.vue'
import BlFlexContainerMv from '@bukalapak/bazaar-mweb/dist/components/BlFlexContainerMv';
import BlCardMv from '@bukalapak/bazaar-mweb/dist/components/BlCardMv';
import BlTextAv from '@bukalapak/bazaar-mweb/dist/components/BlTextAv';
import BLBPicture from '~/components/shared/base/BLBPicture.vue';
import BlButtonAv from '@bukalapak/bazaar-mweb/dist/components/BlButtonAv';
import FooterAtmMitra from '~/components/FooterAtmMitra.vue';

export default {
  name: 'AtmMitraPage',
  components: {
    Navigation,
    AtmMitraHero,
    BlFlexContainerMv,
    BlCardMv,
    BlTextAv,
    BLBPicture,
    BlButtonAv,
    FooterAtmMitra
  },
  data(){
    return {
      isFullDescription: false,
      benefits: [
        {
          image: '/images/landing/atm-mitra/benefit/benefit-1.png',
          name: 'Lengkap di Satu Aplikasi',
          description: 'Tersedia beragam fitur mulai dari Kirim Uang, Bayar QRIS, Setor & Tarik Tunai, Produk Digital & PPOB dan lainnya.'
        },
        {
          image: '/images/landing/atm-mitra/benefit/benefit-2.png',
          name: 'Daftarnya Mudah & Cepat',
          description: 'Prosesnya cepat, cuma modal KTP.'
        },
        {
          image: '/images/landing/atm-mitra/benefit/benefit-3.png',
          name: 'Transaksi Aman & Untung Besar',
          description: 'Bekerja sama dengan berbagai bank & institusi keuangan yang diawasi oleh Bank Indonesia'
        }
      ],
      services1: [
        {
          image: '/images/landing/atm-mitra/service/kirimuang_dope_highlight.png',
          name: 'Kirim Uang',
          description: 'Transfer instan, biaya murah, & real-time ke 120+ bank tujuan.',
          available: true
        },
        {
          image: '/images/landing/atm-mitra/service/tarik_tunai_dope_highlight.png',
          name: 'Tarik Tunai',
          description: 'Bisa tarik tunai dengan bayar pakai QRIS tanpa biaya admin.',
          available: false
        },
        {
          image: '/images/landing/atm-mitra/service/catat_transaksi_dope_highlight.png',
          name: 'Setor Tunai',
          description: 'Tambah saldo tabungan gak perlu ke bank, di Mitra bisa.',
          available: false
        },
        {
          image: '/images/landing/atm-mitra/service/qris_dope_highlight.png',
          name: 'Bayar QRIS',
          description: 'Transaksi apa pun bisa tinggal scan dengan QRIS.',
          available: true
        },
      ],
      services2: [
        {
          image: '/images/landing/atm-mitra/service/bayar_va_dope_highlight.png',
          name: 'Bayar VA',
          description: 'Bisa terima pembayaran dari bank mana pun dalam 24 jam.',
          available: true
        },
        {
          image: '/images/landing/atm-mitra/service/pulsa_dope_highlight.png',
          name: 'Pulsa & Paket Data',
          description: 'Jual pulsa 24 jam, ada cashback & diskon s.d. Rp10rb/transaksi.',
          available: true
        },
        {
          image: '/images/landing/atm-mitra/service/tokenlistrik_dope_highlight.png',
          name: 'Token Listrik',
          description: 'Jual token listrik 24 jam non-stop dengan instan & mudah.',
          available: true
        },
        {
          hidden: true
        }
      ],
      reviews: [
        {
          image: '/images/landing/atm-mitra/review/mitra-udin.png',
          name: 'Pak Udin',
          location: 'Warung Udin, Yogyakarta',
          review: '“Saya bangga warung kecil bisa transaksi kirim uang ke bank mana aja dengan biaya admin murah. Intinya saya senang banget ada fitur ini, karena nambah pelanggan, dan dapet keuntungan. Banyak pelanggan yang datang ke warung saya cuma demi fitur ini.”'
        },
        {
          image: '/images/landing/atm-mitra/review/mitra-wenny.png',
          name: 'Bu Wenny',
          location: 'Wenny Cell, Bogor',
          review: '“Saya senang banget kalau pelanggan saya bolak-balik datang ke warung untuk kirim uang. Nah untungnya, kirim uang di Mitra Bukalapak itu cepat. Pelanggan saya juga senang dan percaya kalau kirim uang di warung saya itu aman.”'
        },
        {
          image: '/images/landing/atm-mitra/review/mitra-ranti.png',
          name: 'Bu Ranti',
          location: 'Warung Mamak, Karawang',
          review: '“Warung saya dapat kesan baik karena layanan kirim uang yang aman dan cepat. Saya paling senang kalau transaksi cepat sampai, karena pelanggan saya juga senang. Saya juga punya pelanggan setia yang rutin datang ke warung buat kirim uang.”'
        }
      ]
    }
  },
  methods: {
    toggleDescription(){
      this.isFullDescription = !this.isFullDescription
    }
  }
}
</script>

<style lang="scss" scoped>
#atm-mitra-hero{
  padding-top: 74px;
}
#atm-mitra-benefit{
  padding: 60px;
  .atm-mitra-benefit-item{
    width: 30%;
    background: #E8E9F0;
  }
  picture{
    width: 50%;
  }
}
#atm-mitra-service{
  padding: 60px;
  .atm-mitra-service{
    background: #E8E9F0;
    padding: 80px 40px 100px 40px;
    .atm-mitra-service--list .bl-card{
      width: 22%;
      &.hidden{
        background: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
}
#atm-mitra-review{
  padding: 60px;
  .atm-mitra-review--photo{
    width: 15%;
  }
}
#atm-mitra-guide{
  padding: 60px;
  .atm-mitra-guide{
    background: #FFEBEB;
  }
  .atm-mitra-guide-title-header{
    font-size: 60px;
    line-height: 68px;
    font-weight: 700;
  }
  .atm-mitra-guide--slide{
    width: 30%;
    .atm-mitra-guide--slide-image{
      width: 50%;
    }
  }
}
#atm-mitra-description{
  padding: 40px;
  border-top: solid 1px #E8E9F0;
  border-bottom: solid 1px #E8E9F0;
  .atm-mitra-description--description{
    width: 70%;
  }
  .atm-mitra-description--button{
    cursor: pointer;
  }
}
#atm-mitra-footer{
  padding: 40px;
}
</style>
