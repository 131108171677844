import axios from './axios-instance';

const CMS_WEBSITE_ID_MAPPING = {
  'mitra': 2,
  'mitra-finance': 24
};

function getCmsWebsiteId() {
  return process.env.CMS_WEBSITE_ID || CMS_WEBSITE_ID_MAPPING[window.location.host.split('.')[0]] || CMS_WEBSITE_ID_MAPPING.mitra;
}

function getCmsOriginCategoryMapping() {
  return process.env.CMS_ORIGIN_CATEGORY || window.location.host.split('.')[0] || 'mitra';
}

export const retrieveArticle = articleId => axios.$get(`/api/cms/websites/${getCmsWebsiteId()}/articles/${articleId}`);

export const retrieveArticleSSR = articleId => {
  const user = process.env.KONTENZ_BASIC_USER;
  const password = process.env.KONTENZ_BASIC_PASSWORD;
  const Authorization = `Basic ${new Buffer(`${user}:${password}`).toString('base64')}`;

  return axios.$get(`${process.env.KONTENZ_INTERNAL_HOST}/_internal/cms/websites/${getCmsWebsiteId()}/articles/${articleId}`, {
    headers: { Authorization }
  });
};

export const retrieveArticles = ({ offset = 0, limit = 9, categoryId = null, keywords = '' } = {}) => {
  const params = { offset, limit };
  if (categoryId) params.category_id = categoryId;
  if (keywords) params.title = keywords;

  return axios.$get(`/api/cms/websites/${getCmsWebsiteId()}/articles`, { params });
};

export const retrieveArticlesSSR = ({ offset = 0, limit = 9, categoryId = null, keywords = '', topic = '', sort = '', excludedId = null } = {}) => {
  const user = process.env.KONTENZ_BASIC_USER;
  const password = process.env.KONTENZ_BASIC_PASSWORD;
  const Authorization = `Basic ${new Buffer(`${user}:${password}`).toString('base64')}`;

  const params = { offset, limit };
  if (categoryId) params.category_id = categoryId;
  if (keywords) params.title = keywords;
  if (topic) params.topic = topic;
  if (sort) params.sort = sort;
  if (excludedId) params.excluded_id = excludedId;

  return axios.$get(`${process.env.KONTENZ_INTERNAL_HOST}/_internal/cms/websites/${getCmsWebsiteId()}/articles`, {
    headers: { Authorization },
    params,
  });
};

export const retrieveArticleAnchors = () => {
  return axios.$post('/api/_exclusive/neo/configs', { id: ['o2o-act/article-anchors'] });
};

export const retrieveCategory = ({ paramSlug }) => axios.$get((`/api/contents/categories?origin=${getCmsOriginCategoryMapping()}&slug=${paramSlug}`));

export const retrieveCategories = (offset = 0, limit = 20) => axios.$get(`/api/contents/categories`, { params: { origin: getCmsOriginCategoryMapping(), offset, limit } });

export const retrieveHighlightsAndPopular = () => axios.$get(`/api/cms/websites/${getCmsWebsiteId()}/pages/slugs/artikel`);
