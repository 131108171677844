<template lang="pug">
  .c-landing-page
    AtmMitraHeroDesktop.desktop
    //- LandingPageHeroMobile.mobile
</template>

<script>
import AtmMitraHeroDesktop from './desktop.vue';
// import LandingPageHeroMobile from './mobile';

export default {
  name: 'AtmMitraHero',
  components: {
    AtmMitraHeroDesktop,
    // LandingPageHeroMobile,
  },
};
</script>
